import { FC, MouseEvent, useContext, useEffect, useState, type JSX } from "react";
import {
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
  Link,
  Text,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  MenuDivider,
} from "@fluentui/react-components";
import {
  BranchFork16Regular,
  bundleIcon,
  ChevronDown12Regular,
  ChevronRight12Regular,
  Delete16Regular,
  DeleteFilled,
  DeleteRegular,
  DeveloperBoardSearchFilled,
  DeveloperBoardSearchRegular,
  Edit16Regular,
  EditFilled,
  EditRegular,
} from "@fluentui/react-icons";
import { useHistory } from "react-router-dom";
import { mapDynamicElement } from "../../helpers/mapDynamicElement";
import { WalterContext } from "@src/taskpane/providers/walter/index.tsx";
import { ContentControlType } from "../../../lib/liquidx";
import { CollapsibleSection } from "../CollapsibleSection";
import { useDynamicElements } from "../../hooks/useDynamicElements";
import { syncEngine } from "@src/lib/sync-engine/SyncEngine";
import { DynamicElement, LiquidElement } from "@src/lib/liquidx/internal";
import { InspectOoxml } from "@src/lib/sync-engine/operations/InspectOoxml";
import { useFocusedDynamicElement } from "@src/taskpane/hooks/useDynamicElement";
import {
  CalendarFold,
  CircleDot,
  GitBranchPlus,
  LinkIcon,
  PenTool,
  RefreshCw,
  SquareAsterisk,
  SquareCheck,
  TextCursorInput,
  TriangleAlert,
} from "lucide-react";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);
const EditIcon = bundleIcon(EditFilled, EditRegular);
export const InspectIcon = bundleIcon(DeveloperBoardSearchFilled, DeveloperBoardSearchRegular);

const useStyles = makeStyles({
  wrapper: {
    display: "grid",
    gridTemplateColumns: "24px 20px 1fr auto",
    alignItems: "center",
    ...shorthands.padding("8px", "0"),
    ...shorthands.border("1px", "solid", tokens.colorTransparentBackground),
    "&:hover": {
      cursor: "pointer",
      ...shorthands.border("1px", "solid", tokens.colorBrandStroke1),
      color: tokens.colorBrandForeground1,
    },
    "&:hover .element-actions": {
      display: "grid",
    },
    "&:last-child": {
      marginBottom: "1px",
    },
  },
  current: {
    backgroundColor: tokens.colorNeutralBackground1Selected,
  },
  title: {
    marginLeft: "4px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    ...shorthands.overflow("hidden"),
  },
  ancestor: {},
  folderIcon: {
    color: tokens.colorNeutralForeground2,
    paddingLeft: "10px",
    paddingRight: "6px",
    height: "100%",
    width: "8px",
  },
  actions: {
    display: "none",
    gridTemplateColumns: "16px 16px",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.gap("8px"),
    marginLeft: "4px",
    marginRight: "8px",
    "> button": {
      display: "flex",
      alignItems: "center",
      color: tokens.colorBrandForeground2,
      "&:hover": {
        color: tokens.colorBrandForeground2Hover,
      },
      "&:active": {
        color: tokens.colorNeutralForeground1Pressed,
      },
      "&:disabled": {
        color: tokens.colorNeutralForegroundDisabled,
      },
    },
  },
});

export const ElementsPane: FC = () => {
  const { dynamicElements } = useDynamicElements();

  return (
    <CollapsibleSection title="Elements" maxHeight={600}>
      <div data-testid="elements-in-doc">
        {dynamicElements.map((dynamicElement) => (
          <DynamicElementItem key={dynamicElement.id} dynamicElement={dynamicElement} />
        ))}
        {dynamicElements.length === 0 && (
          <Text italic style={{ padding: "12px", display: "flex", justifyContent: "center" }}>
            No elements
          </Text>
        )}
      </div>
    </CollapsibleSection>
  );
};

export const ErrorsPane: FC = () => {
  const { dynamicElementsById } = useDynamicElements();
  const dynamicElementsWithErrors: DynamicElement[] = Array.from(dynamicElementsById.values()).filter(
    (element) => element?.hasErrors,
  );

  if (dynamicElementsWithErrors.length === 0) return null;

  return (
    <CollapsibleSection title="Errors">
      {dynamicElementsWithErrors.map((dynamicElement) => (
        <DynamicElementItem key={dynamicElement.id} dynamicElement={dynamicElement} hideChildren />
      ))}
    </CollapsibleSection>
  );
};

const unrenderedTypes: string[] = [ContentControlType.IF_BRANCH, ContentControlType.ELSE_BRANCH];

const icons: Record<string, JSX.Element> = {
  signature: <PenTool size="16" strokeWidth={1.5} />,
  initials: <SquareAsterisk size="16" strokeWidth={1.5} />,
  dateSigned: <CalendarFold size="16" strokeWidth={1.5} />,
  text: <TextCursorInput size="16" strokeWidth={1.5} />,
  checkbox: <SquareCheck size="16" strokeWidth={1.5} />,
  radio: <CircleDot size="16" strokeWidth={1.5} />,
  if: <GitBranchPlus size="16" strokeWidth="1.6" />,
  link: <LinkIcon size="16" strokeWidth="1.6" />,
  ifElse: <BranchFork16Regular />,
  warning: <TriangleAlert size="16" style={{ color: tokens.colorPaletteRedForeground1 }} />,
};

const DynamicElementItem: FC<{ dynamicElement: DynamicElement; depth?: number; hideChildren?: boolean }> = ({
  dynamicElement,
  depth = 0,
  hideChildren = false,
}) => {
  const { selectedDynamicElementIds, showNewUi } = useContext(WalterContext);
  const focusedDynamicElement = useFocusedDynamicElement();
  const history = useHistory();
  const styles = useStyles();
  const { visit, iconSmall } = mapDynamicElement(dynamicElement);
  const icon = icons[dynamicElement.iconType ?? ""] ?? iconSmall;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [className, setClassName] = useState(styles.wrapper);
  const [children, setChildren] = useState<DynamicElement[]>([]);

  const onEdit = () => {
    if (visit) visit(history, dynamicElement);
  };

  const handleDelete = () => void dynamicElement.delete();

  useEffect(() => {
    const newChildren = dynamicElement.children.filter((child) => !unrenderedTypes.includes(child.type));

    const selectableIds = [dynamicElement.id];
    dynamicElement.children
      .filter((child) => unrenderedTypes.includes(child.type))
      .forEach((child) => {
        selectableIds.push(child.id);

        for (const grandchild of child.children) {
          newChildren.push(grandchild);
        }
      });

    setChildren(newChildren);
    setClassName(
      mergeClasses(styles.wrapper, focusedDynamicElement?.id === dynamicElement.id ? styles.current : styles.ancestor),
    );
  }, [focusedDynamicElement?.id, dynamicElement.children, dynamicElement.id, selectedDynamicElementIds, styles]);

  function handleDoubleClick() {
    return;
  }

  useEffect(() => {
    const descendantIds = dynamicElement.descendantIds;

    // Expand if a descendant is selected
    if (selectedDynamicElementIds.some((selectedId) => descendantIds.includes(selectedId))) {
      setExpanded(true);
    }
  }, [dynamicElement.descendantIds, selectedDynamicElementIds]);

  function handleExpand(e: MouseEvent) {
    e.stopPropagation();
    setExpanded((expanded) => !expanded);
  }

  const folderIconProps = { className: styles.folderIcon, onClick: () => setExpanded(!expanded) };
  const folderIcon = expanded ? (
    <ChevronDown12Regular {...folderIconProps} onClick={handleExpand} />
  ) : (
    <ChevronRight12Regular {...folderIconProps} onClick={handleExpand} />
  );

  async function handleInspect() {
    await syncEngine.perform(new InspectOoxml(dynamicElement.id));
    dynamicElement.inspect();
  }

  async function handleClick() {
    await dynamicElement.focus();
  }

  const hasChildren = !hideChildren && children.length > 0;
  const warn = showNewUi && dynamicElement instanceof LiquidElement;

  return (
    <>
      <Menu openOnContext>
        <MenuTrigger disableButtonEnhancement>
          <div
            className={className}
            style={{ paddingLeft: `${depth * 16}px` }}
            onClick={handleClick}
            title={dynamicElement.errors.join("\n") || dynamicElement.title}
          >
            {hasChildren ? folderIcon : <div>&nbsp;</div>}
            {warn && (
              <div style={{ display: "flex", alignContent: "center", color: tokens.colorPaletteRedForeground1 }}>
                {icon}
              </div>
            )}
            {!warn && icon}
            <span className={styles.title} onDoubleClick={handleDoubleClick}>
              {dynamicElement.labelOrTitle}
            </span>
            <div className={`element-actions ${styles.actions}`}>
              {visit && !showNewUi ? (
                <Link
                  as="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    onEdit();
                  }}
                >
                  <Edit16Regular />
                </Link>
              ) : (
                <div>&nbsp;</div>
              )}
              <Link
                as="button"
                onClick={(event) => {
                  event.stopPropagation();
                  handleDelete();
                }}
              >
                <Delete16Regular />
              </Link>
            </div>
          </div>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            {visit && !showNewUi && (
              <MenuItem onClick={onEdit} icon={<EditIcon />}>
                Edit
              </MenuItem>
            )}
            <MenuItem
              onClick={() => void dynamicElement.rebuild()}
              icon={<RefreshCw width="16" strokeWidth="1.5" />}
              title="Rebuild labels and other derived information"
            >
              Rebuild
            </MenuItem>
            <MenuItem onClick={handleDelete} icon={<DeleteIcon />}>
              Delete
            </MenuItem>
            {import.meta.env.DEV && (
              <>
                <MenuDivider />
                <MenuItem onClick={handleInspect} icon={<InspectIcon />}>
                  Inspect
                </MenuItem>
              </>
            )}
          </MenuList>
        </MenuPopover>
      </Menu>

      {hasChildren &&
        expanded &&
        children.map((child) => <DynamicElementItem key={child.id} dynamicElement={child} depth={depth + 1} />)}
    </>
  );
};
